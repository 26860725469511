<template>
  <div>
    <!-- the idea is to style stuff. here we see created on, created by. Logically, with human intervention we know these belong together.
    However right now we plan on bringing in all entity record fields, and making a "item" for 2 fields at a time.
    Realisticlaly how do the group together? So below you see avatar beause date styled "card", meaning essentially it's for a date.
    But you need to know that BEFORE you know to wrap in avatar.
    So it's kind of hard to know what to do here.
    Perhaps this is a  holder, calling multiple fields at a time if that makes sense?
    If this or that, make avatar, if that or that... -->
    <v-list-item dense class="caption">
          <v-list-item-avatar>
           <DateRender :Value="record.Created_On"
              :Display="'Card'" :Color="'grey'"
              /> 
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              Created By:  
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-avatar :size="MiniView ? 20 : 35">
                  <v-img :src="record.Created_By && record.Created_By.Profile_Photo ? record.Created_By.Profile_Photo :
                  require('@/assets/BlankProfilePic.png')"/>
                  </v-avatar><span v-if="record.Created_By">{{' '+record.Created_By.Full_Name}}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
           <v-list-item-avatar>
           <DateRender :Value="record.Modified_On"
              :Display="'Card'" :Color="'grey'"
              /> 
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              Modified By:  
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-avatar :size="MiniView ? 20 : 35">
                  <v-img :src="record.Modified_By && record.Modified_By.Profile_Photo ? record.Modified_By.Profile_Photo :
                  require('@/assets/BlankProfilePic.png')"/>
                  </v-avatar><span v-if="record.Modified_By">{{' '+record.Modified_By.Full_Name}}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {

        }
    },	
    computed:{
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{

    }
}
</script>

<style>

</style>



